.App {
  text-align: center;
}

.header {
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
}

.container {
  background-color: white;
  margin: 1em auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95vw;
  border-radius: 5vw;
}

#playing-board .container {
  height: 95vw;
}

#next-tiles-to-place .container {
  color: #223;
}

#next-tiles-to-place .container .tile-space {
  color: #223;
  padding: 1em 0;
  flex-direction: row;
  display: flex;
  border: 1px solid black;
}

#next-tiles-to-place .container .tile-space .tile canvas {
  width: 32px;
}

#next-tiles-to-place .container .tile-space .rotate-buttons {
  color: #223;
  width: 64px;
}

.rotate-icon {
  background: #223;
  width: 32px;
  height: 32px;
  mask-size: cover;
}

.rotate-icon.cw {
  mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQflARgHFyQeXwCTAAAD+0lEQVRo3sWZXWxURRTHf+1uF5ouXZbWwFIKBoG2CoWAqDFBUyShiELghfjAp9H4YIIEAjzxysMCJalEERNJSJRgjIm+oMGmQhANsCSU0JDGhvLR8LHVTbtb2y675aHZXLgzs/djpvXM273nnN9/5t4zc2duAD3bThm9OglKNQUspJWF/6eAPK9zQkeCrgCAN3QkmBCgJcGMAA0JpgT4lqD/EmpKCPqAllBJlGqilFMvjMJH3PCWzIuFqeM1llNHDZVMIkhQGMM/vUpwK3QBuzhHklHHdklvapLZIg7T7QI9LhJiHKDHA3ys/WFGQgnNXPQMH2u/E9PFV7DP1TOXtSccJayHr+ZLsr7xLbr46XznE24EX8W3DpA8A9yli77xwJdzrAh6mA6O8yErqCMmeBrAwy6GFfAMP7OJmc/Mn3Hz+CYeKPAX2UiFzTtuGl9Nm6LvR6R1HTeLh93kJPgkn1Am9Y+bxc/jlgTfx2ZlRNwkHg5I8IN8WmTRjpvEz+KGRMDnTCoSc4hRU3jYyhMBf5XZRWMO0moKH+J7yaSzxSFqMREzeJgvWfXbmWoqvcqsL7olQp3nOU1q4gQsFSr9Dr+NN94SEOJl4d41eiZOwBRqhXuXGZk4ARGitjtZusYfbwkIC+vcEPcmQkBhaxYS5rsRMsa7adkoo88LKBWccuQM4WvZT7gABCDNIW4/L0DElSkWYO8WY4ttuv6Xr+2DM8R/trCQsVkwSsB2JWPRCgIGSNucypljSEAt5bYrafrtAlIkbU6lvGJIgLhLfCQKyNAtuC03stBGeFW49rf4CHKSQ4VGGoz0XxzJDutoxyq+Kwza3Kp434CAdcLLPEBC5hiTfJB18qImfj5dQtYEL1gO1gg84LwQXs82LXwJO5gnXG3nsdy9mbSg9j5vagh4m4dCxn5Wqtwj0l3ROWb4xNdwXpLvbLHa2saIJOQEU3zgI5yU5Brig2JB02iXHjgcpdIjfirHpJu8X52+ojcwIJXwDbM84GdzSopP8a5TaIivFJvzC7zj6mQ5wGouKXK0ullhXyKhCE/SwqKiIkpZTCv/KOL/cru8rZYUT6Hd4QvWMENYYAPEWMtx7ikj78vLT77v/ZjDRYplkG6u00kPKUYIEWUODTQyV1h2LetnJyfd9R8gyF4GlX0ptDxZhsiSd/RM85kwZg5Wxh76HRO7ayl2+vkvEWAHvQbwd9ns/7/MSmVBuW0XeMsvfMxqaVGWlVNLEqdGDw8QZBU/kfEIT/MjTV5fPLWFWc8Pro/uH3GG94StnsLc/7SaTCPNNNFAleKdztLHTdr4hQ6G3ab19tcMItSxjKXUM5NpVBAgR4Y+eukkQYJb1ge3O3sKgvOkMh8JLHUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDEtMjRUMDc6MjI6MzArMDA6MDBjPjZtAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAxLTI0VDA3OjIyOjMwKzAwOjAwEmOO0QAAAABJRU5ErkJggg==')
}

.rotate-icon.ccw {
  mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQflARgHGRivs1GaAAAENUlEQVRo3sWYTUxcVRTHf8x0IHWmMO1o7JRSjNYPcGgNkWhc2IAmtFo1ujGa1NCu3GnTxqVrk1HRoFHLohoXfsVodNNoa2kNEK2iFhIkjSS0paEVEOkMA8N8uBjfDLx778x7795Mz9lMzpxz//977j3vnfNATzo4qLeATys6Rh+xG0cgRj8PkLtRBGL086AeuA4BQ/BeCRiD90bAILwXAnZ4zUvo1979HEvsYAu15Em7J1CjCQ85MmRYYZFpJjjHz0yQ0MuKGn6YfEWd5SSHucvl1ozBWzrJG7SZhR9yAV/QKV4lagY+yhnX8AUdZG/5w3BaBa10eHpmNLGPGn5nVTcHIXrJeMzCKu9zs/4x6FDI8wm36h0BpBkkKDmIeaZZwkeg7FnHaGSAlA4BFYXjHOQzvmaIGeoIK1dsI8RpMuYPIl78r4ZtPMu3JBXHsMJhXXgZhbjt/yDPMKigMEOneQpxiUeUNxV5+MFEPaynEJd6BHiRWQmBLEdMEFhLIa70OcCchMIEO9c6ue0HLClVxBDfK3xGSdJJwGaNMMcZc1nI83oZjzrekeRgjO36GbCyUM8yp5QeWcbYI7wRI4zyh5kcQAO7K3i8wIqQgy+oNUWgsoQZkHQKd1p/682GTmSBT4XOOcp91SMAp7hoswRoryaBKX4TbK3WLagGgTTnBFsTm6pHAC4ILdlmGqpJ4DLLNkuQUOHHhqKpRtLRaM59RUkKQ1uddQdKBJo5arH6n1CC17hkhECWrM3is3JfIhDmeTavc0rwkSECAeGVVKRUugMpkjYnv42QdwkLj96UdStKBBaFmXYjTYYINLPRZklwXSRwTQjU/ARXlHuFaptlwU4gxV9C4P1WtWpJiA7BNmkdeIlAjlEJcxM5aGGXYBsTLyGMWOdSlDBPGiDwBBGbZYlfZI63MCK8uS+U3twe5TbGJU1ZsUtam4G/GRDCd3JI82NLD/cItrPMyJ27WBTYXmWPBvxDTAsrJtircg9xQtLFnqXRI/xWTkqnozK19RzLkpAPPZXjJvola6XpKRfUwHfSgepdwi7h63lL+kFjgC3lAx9jQUrhY3a4gN/OcSn8dZ6uFBqgTzFcD9PtaJDx8Qg/KtY45mQiaOYnRfg8fewu20X5aKNXOhfnyTPCHc4S2CUpHksv8wGPExVy4Wcr+3iPi8rIq3SLUKqHTA9vU68kmGKS84wzxT+kqSVMMy3s4nZuUsYkOMIxZ/sv7OdlEsq9WJpjlWVWyVX0XOKVNd2XI9nAS9J68KKLHBWaMgfi4wCXDMBf4ZD3zwAPKwvKqQ7T5RW8II3ElWVVSefpNdFX+unkKwdXcr0m+YZH3V48tQTZz+dccwg+y5c8tW7MUYqbZqOONrrpopWI4k5nmGOc05zgvDANGiBQkHrupp12WthGhCB+siSZ5wp/MsKvTPCvm+X+A3fUXg5V4bNZAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAxLTI0VDA3OjI0OjU4KzAwOjAwm6ABygAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0yNFQwNzoyNDo1OCswMDowMOr9uXYAAAAASUVORK5CYII=')
}

.header-container {
  display: flex;
  margin-top: .5em;
  width: 100vw;
  justify-content: space-around;
}

.header-container .element {
  background-color: #eef;
  color: #223;
  display: flex;
  border-radius: 1em;
  padding: .5em;
  display: flex;
  align-items: center;
}

.instruction {
  width: 45vw;
}

.score {
  width: 24vw;
  text-align: left;
  justify-content: space-between;
}

.menu-button {
  width: 1.5em;
  height: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hamburger {
  height: .3em;
  width: 1.2em;
  background-color: #223;
  border: 1px solid #eef;
  border-radius: .2em;
}

.menu-container {
  z-index: 2;
  position: absolute;
  background-color: #335;
  width: 50vw;
  right: 0;
}

.menu-item {
  height: 2em;
  border: 1px solid #112;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-over-overlay {
  position: fixed;
  z-index: 3;
  background-color: #eef;
  top: 10vh;
  bottom: 10vh;
  left: 10vw;
  right: 10vw;
  color: #223
}

.game-over-overlay section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.game-over-overlay .your-score-is {
  text-align: right;
}

.game-over-overlay .score {
  font-weight: 800;
  text-align: center;
}

.game-over-overlay header {
  display: flex;
  justify-content: flex-end;
}

.game-over-overlay .close-button {
  width: 2em;
  height: 2em;
  margin: .5em;
  border: 1px solid #223;
  border-radius: .2em;
}

.x-up,
.x-down {
  height: .3em;
  width: 2em;
  background-color: #223;
  border: 1px solid #eef;
  border-radius: .2em;
}


.x-up {
  transform: rotate(45deg);
  z-index: 20;
  margin-top: .8em;
}
.x-down {
  transform: rotate(-45deg);
  z-index: 21;
  margin-top: -.4em;
}
